
              @import "@/assets/css/variables.scss";
            

























































































































































































































































































.change_leverage_drawer {
  .reminder-box {
    .desc {
      font-size: 12px;
      color: $text-secondary;
    }
  }

  .tnc {
    .el-form-item__content {
      .el-checkbox__label {
        @include rtl-sass-prop(padding-left, padding-right, 8px);

        .tnc_label {
          display: flex;
          align-items: center;

          >p {
            color: $text-link !important;
            cursor: pointer;
          }
        }
      }

      .tnc_text_box {
        p {
          font-size: 12px;
          color: $text-secondary;
          line-height: 22px;

          a {
            color: $text-link;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .info {
    .el-form-item__content {
      font-size: 12px;
      color: $text-secondary;
    }
  }
}
