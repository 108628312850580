
              @import "@/assets/css/variables.scss";
            






































#CurrencyIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: $white;
}
